import React from 'react';

// local imports
import Banner from "../components/Banner";

const Home = () => {
  return(
    <section>
      <Banner></Banner>
    </section>
  )
}

export { Home };